import styled from '@emotion/styled';

import { colourTokens } from '@/styles/colours';

export interface DividerProps {
  orientation?: 'vertical' | 'horizontal';
}

export const Divider = ({ orientation = 'horizontal' }: DividerProps) => {
  return <DividerElement orientation={orientation} />;
};

const DividerElement = styled.hr<Pick<DividerProps, 'orientation'>>`
  height: ${({ orientation }) =>
    orientation === 'horizontal' ? '1px' : '100%'};
  width: ${({ orientation }) =>
    orientation === 'horizontal' ? '100%' : '1px'};
  background-color: var(${colourTokens.border.warmSubtle.token});
  border: none;
  margin: 0;
`;
