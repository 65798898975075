import { useCallback, useEffect, useRef } from 'react';
import styled from '@emotion/styled';
import SlickSlider from 'react-slick';

import { screen } from '@/components/common/breakpoints';
import { IconOnlyButton } from '@/components/common/Buttons';
import { Image } from '@/components/common/image';

const LogoItem = ({ dots, index, slideItem, componentClassName }) => {
  const buttonProps = dots[index].props.children.props;
  const buttonClassName = dots[index].props.className;

  const dotsProps = dots[index].props;

  return (
    <li>
      <button
        type="button"
        {...buttonProps}
        className={`${buttonClassName} ${componentClassName}__slide-logo-indicator`}
        aria-label={`Slide ${index + 1}: ${slideItem.company}`}
        aria-current={dotsProps.className.includes('slick-active')}
      >
        <Image asset={slideItem.logo} alt="" />
      </button>
    </li>
  );
};

const SliderDots = ({
  dots,
  slideItems,
  componentClassName,
  sliderPosition,
  sliderViewports,
}) => (
  <>
    <StyledIndicatorList
      sliderViewports={sliderViewports}
      className="slick-dots"
      {...dots.props}
    >
      {dots.map(({ props, key }, index) => {
        return (
          <li key={key} {...props}>
            <button
              type="button"
              {...props.children.props}
              aria-current={props.className.includes('slick-active')}
              className={`${componentClassName}__slide-indicator`}
              aria-hidden={
                slideItems[0].logo && slideItems[0].company && 'true'
              }
            >
              Slide {index + 1}
            </button>
          </li>
        );
      })}
      <ActiveIndicator
        sliderPosition={sliderPosition}
        sliderViewports={sliderViewports}
      />
    </StyledIndicatorList>
    {slideItems[0].logo && slideItems[0].company && (
      <StyledLogoList>
        {slideItems.map((slideItem, index) => (
          <LogoItem
            key={dots[index].key}
            dots={dots}
            index={index}
            slideItem={slideItem}
            componentClassName={componentClassName}
          />
        ))}
      </StyledLogoList>
    )}
  </>
);
const defaultSliderSettings = ({
  slideItems,
  componentClassName,
  sliderTrackingTag,
  sliderPosition,
  sliderViewports,
}) => ({
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  appendDots: (dots) => (
    <SliderDots
      dots={dots}
      slideItems={slideItems}
      componentClassName={componentClassName}
      sliderPosition={sliderPosition}
      sliderViewports={sliderViewports}
    />
  ),
  nextArrow: (
    <IconOnlyButton
      variant="solid-light"
      size="md"
      icon="ChevronRight"
      aria-label="next slides"
      className={
        sliderTrackingTag?.blockName &&
        `tracking-${sliderTrackingTag.blockName}__${sliderTrackingTag.blockAction}-next`
      }
    />
  ),
  prevArrow: (
    <IconOnlyButton
      variant="solid-light"
      size="md"
      icon="ChevronLeft"
      aria-label="previous slides"
      className={
        sliderTrackingTag?.blockName &&
        `tracking-${sliderTrackingTag.blockName}__${sliderTrackingTag.blockAction}-prev`
      }
    />
  ),
});

export const SliderV2 = ({
  settings,
  slideItems,
  children,
  componentClassName = 'tracking-slider',
  sliderTrackingTag = null,
  sliderPosition = 0,
  sliderViewports = 0,
  ...props
}) => {
  const sliderWrapperRef = useRef(null);
  const hasMultipleSlides = slideItems.length > 1;

  const initLinksTabIndex = () => {
    const inactiveSlides = sliderWrapperRef?.current?.querySelectorAll(
      '.slick-slide:not(.slick-active)',
    );

    inactiveSlides?.forEach((inactiveSlide) => {
      const inactiveLinks = inactiveSlide.querySelectorAll('a');
      inactiveLinks?.forEach((link) => {
        // eslint-disable-next-line no-param-reassign
        link.tabIndex = -1;
      });
    });
  };

  useEffect(() => {
    setTimeout(() => {
      initLinksTabIndex();
    }, 100);
  }, []);

  const handleAfterChange = useCallback(() => {
    initLinksTabIndex();

    const currentSlide = sliderWrapperRef.current.querySelector(
      '.slick-slide.slick-current',
    );
    const currentLinks = currentSlide.querySelectorAll('a');
    currentLinks?.forEach((link) => {
      // eslint-disable-next-line no-param-reassign
      link.tabIndex = null;
    });
  }, []);

  return hasMultipleSlides ? (
    <div ref={sliderWrapperRef}>
      <StyledSlider
        {...props}
        {...defaultSliderSettings({
          slideItems,
          componentClassName,
          sliderTrackingTag,
          sliderPosition,

          sliderViewports,
        })}
        {...settings}
        afterChange={handleAfterChange}
        sliderPosition={sliderPosition}
        sliderViewports={sliderViewports}
      >
        {children}
      </StyledSlider>
    </div>
  ) : (
    children
  );
};

const StyledSlider = styled(SlickSlider)`
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  > .slick-track,
  > .slick-list {
    transform: translate3d(0, 0, 0);
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: var(--spacing-large);
  }
  .slick-track:before,
  .slick-track:after {
    display: table;
    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  &.slick-loading .slick-track {
    visibility: hidden;
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  &.slick-initialized .slick-slide {
    display: block;
  }
  &.slick-loading .slick-slide {
    visibility: hidden;
  }
  &.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }

  // Arrow button styling
  .slick-arrow {
    z-index: 2;
    position: absolute;
    display: block;
    cursor: pointer;
    padding: 0;
    border-radius: 100%;
    outline-offset: 2px;

    background-color: var(--surface-warm-strong);
    border: none;
    bottom: 0;
  }

  .slick-prev {
    left: auto;
    right: calc(32px + var(--spacing-100));

    ${screen.sm} {
      right: calc(44px + var(--spacing-300));
    }
  }

  .slick-next {
    left: auto;
    right: 0;
  }

  .slick-disabled {
    opacity: 50%;
  }

  .slick-arrow.slick-hidden {
    display: none;
  }
`;

const StyledIndicatorList = styled.ul<{ sliderViewports: number }>`
  display: flex;
  height: var(--spacing-500);
  position: relative;
  width: 75%;
  max-width: 950px;
  li {
    &:first-of-type {
      button {
        &:before {
          border-radius: var(--radius-l) 0 0 var(--radius-l);
        }
      }
    }
    &:last-of-type {
      button {
        &:before {
          border-radius: 0 var(--radius-l) var(--radius-l) 0;
        }
      }
    }
    position: relative;
    ${({ sliderViewports }) => {
      const progressWidthPercentage = (1 / sliderViewports) * 100;
      return `width: ${progressWidthPercentage}%;`;
    }}

    button {
      font-size: 0;
      line-height: 0;
      padding: 0;
      display: block;
      height: 8px;
      cursor: pointer;
      color: transparent;
      border: 0;
      background: transparent;
      cursor: pointer;
    }

    button:before {
      position: absolute;
      display: block;
      content: '';
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
      width: 126px;
      height: 8px;
      background-color: var(--surface-warm-strong);
      transition: 0.2s ease-out;
      transition-property: background-color, border-radius;
      width: 100%;
    }
  }
`;

const StyledLogoList = styled.ul`
  list-style: none;
  padding: 0;
  margin: var(--spacing-xx-small) 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-x-small) var(--spacing-300);
  flex-wrap: wrap;

  ${screen.sm} {
    flex-wrap: nowrap;
    gap: var(--spacing-400);
  }

  ${screen.md} {
    margin: var(--spacing-x-small) 0;
  }

  ${screen.lg} {
    gap: var(--spacing-500);
  }

  button {
    background: none;
    border: none;
    padding: 0;
    line-height: 0;
    flex: 0 1 auto;
  }

  button:not(.slick-active) {
    cursor: pointer;
  }

  img {
    max-height: var(--spacing-500);
    max-width: var(--spacing-1600);
    height: var(--spacing-500);
    width: auto;
    transition:
      opacity 0.2s ease-out,
      color 0.2s ease-out;

    ${screen.sm} {
      height: var(--spacing-600);
      max-height: var(--spacing-600);
    }

    ${screen.lg} {
      height: var(--spacing-700);
      max-height: var(--spacing-700);
    }
  }

  button:not(.slick-active) img {
    opacity: 0.48;
    color: var(--decorative-ultraviolet);
    transition:
      opacity 0.2s ease-in,
      color 0.2s ease-in;
  }

  button:not(.slick-active) img {
    filter: saturate(0);
  }
`;

const ActiveIndicator = styled.div<{
  sliderPosition: number;
  sliderViewports: number;
}>`
  position: absolute;
  top: calc(50% - 4px);
  border: none;
  height: 8px;
  transition-property: left;
  transition: 0.4s ease-in;
  background-color: var(--surface-primary);
  border-radius: var(--radius-l);

  ${({ sliderPosition, sliderViewports }) => {
    const progressWidthPercentage = (1 / sliderViewports) * 100;
    const sliderPositionPercentage = sliderPosition * progressWidthPercentage;

    return `
    width: ${progressWidthPercentage}%;
    left: ${sliderPositionPercentage}%;
    `;
  }}
`;
